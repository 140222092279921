import moment from 'moment'
moment.locale('en-GB')

export default moment

export function format(date, format = 'LL') {
  return moment(date).format(format)
}

export function formatDateFromBackend(date) {
  const parsedDate = moment(date, 'YYYY-MM-DD', true)

  return parsedDate.isValid() ? parsedDate.format('DD/MM/YYYY') : null
}

export function formatDateToBackend(date) {
  const parsedDate = moment(date, 'DD/MM/YYYY', true)

  return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null
}

export const currentYear = moment().format('YYYY')
export const previousYear = moment().subtract(1, 'year').format('YYYY')

export function getDateRangeLabel(from, to) {
  if (!from || !to) return 'Select time period...'

  const dateFrom = moment(from).startOf('day')
  const dateTo = moment(to).startOf('day')
  const dateFromDay = dateFrom.date()
  const dateFromMonth = dateFrom.format('MMM')
  const dateFromYear = dateFrom.year()
  const dateToDay = dateTo.date()
  const dateToMonth = dateTo.format('MMM')
  const dateToYear = dateTo.year()

  const dateToLabel = `${dateToDay} ${dateToMonth} ${dateToYear}`

  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  if (dateFrom.toString() === dateTo.toString()) {
    return `${dateToDay} ${dateToMonth} ${dateToYear}`
  }
  if (dateFromYear !== dateToYear) {
    return `${dateFromDay} ${dateFromMonth} ${dateFromYear}-${dateToLabel}`
  }
  if (dateFromMonth !== dateToMonth) {
    return `${dateFromDay} ${dateFromMonth}-${dateToLabel}`
  }
  return `${dateFromDay}-${dateToLabel}`
}

export function addOrdinalSuffix(i) {
  const j = i % 10
  const k = i % 100

  if (j === 1 && k !== 11) {
    return `${i}st`
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  return `${i}th`
}
