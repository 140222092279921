import React, { Fragment } from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import { currentYear, previousYear } from 'helpers/date'

import { Typo } from 'components/_old/Typo/Typo'

import { Paper } from 'components/atoms/Paper'
import { Stack } from 'components/atoms/Stack'
import { Typography } from 'components/atoms/Typography'

import { RadioWithLabel } from 'components/molecules/WithLabel/WithLabel.jsx'

import { TransferCurrentYear } from 'constants/transferIsa'

interface CurrentYearTransferProps {
  value: TransferCurrentYear
  setValue: (value: TransferCurrentYear) => void
}

const CurrentYearTransfer: React.FC<CurrentYearTransferProps> = ({ value, setValue }) => {
  return (
    <Fragment>
      <Paper bottom={16}>
        <Typography size={14} weight="semibold">
          <Typo>
            Do you want to transfer ISA contributions from the current tax year ({previousYear}-{currentYear})?
          </Typo>
        </Typography>
      </Paper>
      <RadioGroup
        selectedValue={value}
        onChange={(value: TransferCurrentYear) => {
          setValue(value)
        }}
      >
        <Stack vertical={16}>
          <RadioWithLabel
            CustomInput={Radio}
            label="No, don’t transfer this year’s contributions "
            value={TransferCurrentYear.NO}
            size="smaller"
            onChange={() => {}}
            name="transferCurrentYear"
            testId="transferCurrentYearNo"
            data-test-id="transferCurrentYearNo"
            noMarginBottom
          />
          <RadioWithLabel
            CustomInput={Radio}
            label="Yes, transfer all of my current tax year ISA"
            value={TransferCurrentYear.YES}
            size="smaller"
            onChange={() => {}}
            name="transferCurrentYear"
            testId="transferCurrentYearYes"
            data-test-id="transferCurrentYearYes"
            noMarginBottom
          />
        </Stack>
      </RadioGroup>
    </Fragment>
  )
}

export { CurrentYearTransfer }
